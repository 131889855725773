/**
  WARNING!!! This file should only be altered for boilerplate.
  Changes will be propagated to all sites.
**/

import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import CategoryCard from "../../components/molecules/CategoryCard";

/**
 * Displays a list of categories for the blog
 */

const Categories = (props) => {
  const {
    allStrapiCategory: { nodes: allCategoryData },
  } = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        nodes {
          description
          name
          featured_image {
            url
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="bg-bgSecondary">
        <div className="mx-auto py-12 px-4 max-w-7xl">
          <h2 className="uppercase mb-12 text-3xl font-bold text-center text-primary">
            Categories
          </h2>
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            {allCategoryData.map((item) => (
              <li key={item.id}>
                <CategoryCard category={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Categories;
